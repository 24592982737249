


.FONT {
    font-family: 'Montserrat', sans-serif;
}


.section-header {
    position: relative;
    font-weight: 900;
    padding-bottom: 25px;
    color: #143d50;
}

.section-header::after {
    content: '';
    height: 2px;
    width: 200px;
    position: absolute;
    bottom: 0;
    background-color: #143d50;
}

.section-header span {
    display: block;
    font-size: 16px;
    font-weight: 300;
    margin-top: 14px;
    line-height: 1.4;
}

.testimonials {
    width: 100%;
    height: 100vh;
}

.single-item {
    color: #111;
    padding: 15px;
    margin: 50px 15px;
}

.profile {
    margin-bottom: 30px;
    text-align: center;
}

.img-area {
    margin: 0 15px 15px 15px;
}

.img-area img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
}

.content {
    font-size: 18px;
}

.content p {
    text-align: center;
    font-size: 14px;
}

.content p span {
    font-size: 13px;
    margin-right: 20px;
    color: #295063;
}

.socials i {
    margin-right: 25px;
}

.bio {
    font-family: 'Montserrat', sans-serif;
}


.carousel-control-next-icon {
    margin-left: 80px;
    padding: 0 15px 50px;
}

.carousel-control-prev-icon {
    padding: 0 15px 50px;
    margin-right: 80px;
}


@media only screen and (max-width: 1200px) {
    .testimonials{
        width: 100%;
        height: 100%;
    }
    .carousel-control-next-icon {
        margin-left: 0px;
        padding: 0 15px 50px;
    }
    
    .carousel-control-prev-icon {
        padding: 0 15px 50px;
        margin-right: 0px;
    }
    
}