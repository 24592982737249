

/* ////////////// FONT START /////////////////// */


.HomeFontRALEWAY {
    font-family: 'Raleway', sans-serif;
}

.HomeFontRALEWAYLIGHT {
    font-family: 'Raleway', sans-serif;
}

.HomeFontEXCULSIVEPUNERATE {
    font-family: 'Shadows Into Light';
}

.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}



/* /////  FONT END   ////// */



.btnStyle {
    width: 100%;
    padding: 10px;
    color: #555;
    border: 1px solid #ddd;
    background-color: #fff;
    font-weight: 400;
}

.newStyle {
    width: 100%;
    padding: 10px;
    color: #ffeb3b;
    border: 1px solid #ddd;
    background-color: #1d292e;
    font-weight: 400;
}


.BOOKINGHEADER{
    color: #fff;
}

.BOOKINGHEADER:hover{
    color: #212121;
}



/* //////////////////////////////// */



.FORMWIDTH{
    width: 85%;
}

@media only screen and (max-width: 1200px) {
    
    .FORMWIDTH{
        width: 100%;
    }
}




.OutstationHOVER{
    background-color: #fff;
}

.OutstationHOVER:hover{
    background-color: rgb(225, 223, 223);
    cursor: pointer;
}